import { FormHelperTextClasses } from '@mui/material/FormHelperText/formHelperTextClasses';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const muiFormHelperText: Partial<
  OverridesStyleRules<keyof FormHelperTextClasses, 'MuiFormHelperText', unknown>
> = {
  root: {
    marginLeft: 0,
  },
  contained: {
    marginLeft: 0,
  },
};

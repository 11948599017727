import CircularProgress from '@mui/material/CircularProgress';
import * as S from './LoadingPag.style';

export default function LoadingPag() {
  return (
    <S.Wrapper>
      <CircularProgress size={48} color={'primary'} />
    </S.Wrapper>
  );
}
